import * as React from "react"

// Styles
import "../../styles/faq/faq_section_top.scss"

// markup
const TopSection = () => {
    return (
        <div className="faq_section_top">

            <div className="wrapper-wide">

                <h1>
                    FAQ
                </h1>

                <h2>
                    Super Snow Tubes
                </h2>

                <p>
                    Find answers to frequently asked questions about the game.
                </p>

            </div>

        </div>
    )
}

export default TopSection